
(function($) {
  Drupal.behaviors.Filters = {
    attach: function(context) {
      const $Filters = $('.js-Filters', context);
      $Filters.on('change', function() {
        $Filters.submit();
      });
    }
  };
})(jQuery);
