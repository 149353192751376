(function($) {
  Drupal.behaviors.MainNavigation = {
    attach: function(context) {
      const navigationLogic = function() {
        const $MainNavsWrapper = $('.js-MainNav', context);
        const $MainNav = $MainNavsWrapper.find('.js-MainNav-menu');
        const $MainNavInner = $MainNav.find('.js-MainNav-menu-inner').first();
        const viewWidth = $MainNavInner.width();

        if ($MainNavInner[0] && $MainNavInner[0].scrollWidth > viewWidth) {
          $MainNav.addClass('has-scroll');

          const $arrowLeft = $MainNav.find('.js-MainNav-arrow--left');
          const $arrowRight = $MainNav.find('.js-MainNav-arrow--right');

          $arrowLeft.appendTo($MainNav);
          $arrowRight.appendTo($MainNav);

          $MainNav.on('click', '.js-MainNav-arrow', function() {
            const position = $MainNavInner.scrollLeft() + ($(this).data('scroll-direction') * $MainNavInner.width());
            $MainNavInner.animate({scrollLeft: position});
          });

          const $activeItem = $MainNav.find('.js-active').first();
          if ($activeItem.length) {
            const itemPosition = $activeItem.position().left + ($activeItem.outerWidth() / 2);
            const newPosition = itemPosition - (viewWidth / 2);
            $MainNavInner.scrollLeft(newPosition);
          }

          const arrowCheck = function() {
            const position = $MainNavInner.scrollLeft();
            if ($MainNavInner[0].scrollWidth <= (position + $MainNavInner.width())) {
              $arrowLeft.addClass('is-visible');
              $arrowRight.removeClass('is-visible');
            } else {
              $arrowRight.addClass('is-visible');
            }
            if (position < 1) {
              $arrowLeft.removeClass('is-visible');
              $arrowRight.addClass('is-visible');
            } else {
              $arrowLeft.addClass('is-visible');
            }
          };

          arrowCheck();

          $MainNavInner.scroll(function() {
            arrowCheck();
          });
        } else {
          $MainNav.removeClass('has-scroll');
        }
      };

      navigationLogic();
      $(window).resize(function() {
        navigationLogic();
      });
    }
  };
})(jQuery);
