(function($) {
  let handleSlideChange;
  let resizeEventIsSet = false;

  Drupal.behaviors.SlideShow = {
    attach: function(context) {
      const $AllSlideShows = $('.js-SlideShow', context).each(function() {
        const $SlideShow = $(this);
        const $swiperContainer = $SlideShow.find('.js-SlideShow-container');
        const $SlideShowThumbs = $SlideShow.find('.js-SlideShow-thumb');
        let mySwiper;

        handleSlideChange = function(swiper) {
          const swiperInstance = swiper || mySwiper;
          $swiperContainer.height( $(swiperInstance.slides[swiperInstance.activeIndex]).find('.js-SlideShow-slide-inner').height() );

          $SlideShowThumbs
            .removeClass('is-active')
              .filter('[data-slideshow-index=' + swiperInstance.activeIndex + ']')
              .addClass('is-active');
        };

        mySwiper = new Swiper($swiperContainer, {
          loop: true,
          keyboardControl: true,
          onInit: handleSlideChange
        });
        handleSlideChange();
        mySwiper.on('slideChangeStart', handleSlideChange);

        $SlideShow.find('.js-SlideShow-thumbsWrapper').on('click', '[data-slideshow-index]', function() {
          const slideShowIndex = $(this).data('slideshowIndex');

          mySwiper.slideTo(slideShowIndex);
        });
      });
      if ($AllSlideShows.length > 0 && !resizeEventIsSet) {
        $(window).resize(_.debounce(function() {
          handleSlideChange();
        }, 250));

        resizeEventIsSet = true;
      }
    }
  };
})(jQuery);
